<template>
  <div class="main">
    <form @submit.prevent="fetchReports">
      <table border="0">
      <tbody>
        <tr><td><label for="name">姓名:</label></td><td><input type="text" id="name" v-model="formData.name" required /></td></tr>
        <tr><td><label for="phone">电话:</label></td><td><input type="text" id="phone" v-model="formData.phone" required /></td></tr>
        <tr><td><label for="sampleCode">样本条码:</label></td><td><input type="text" id="sampleCode" v-model="formData.sampleCode" required /></td></tr>

      </tbody>
      </table>
      
      <button type="submit">查询</button>
    </form>
    
    <table v-if="reports.length > 0" border="0">
      <tbody>
        <tr v-for="(report, index) in reports" :key="index">
          <td>姓名:<a :href="report.reportUrl" target="_blank">{{ report.name }}</a>
            <br />
            {{ report.stateStr }}<br />
            出生日期：{{ report.birth }}
          </td>
          <td>报告编号:{{ report.sampleReportCode }}
            <br />报告类型:{{ report.goodsName }}
            <br />
            报告链接: <a :href="report.reportUrl" target="_blank">查看报告</a></td>
        </tr>
      </tbody>
    </table>
    <p v-else>
        <table v-if="reportsmsg.length > 0" border="0">
      <tbody>
        <tr v-for="(report, index) in filteredReportsmsg" :key="index">
          <td> {{ report.state }}. {{ report.stateStr }} </td>
          <td>
            状态：{{ report.stateFlag?'完成':'进行中' }}</td>
        </tr>
      </tbody>
    </table>
    </p>
  </div>
</template>

<script>
import axios from 'axios';


export default {
  name: 'ReportListView',
  data() {
    return {
      reports: [],
      reportsmsg:[],
      formData: {
        name: '',
        phone: '',
        cpid: '',
        sampleCode: ''
      }
    };
  },computed: {
    filteredReportsmsg() {
      return this.reportsmsg.filter(report => report.stateStr && report.stateFlag);
    }
  },
  created() {
 // 从 URL 参数中获取 sampleCode 并赋值给 formData.sampleCode
 this.formData.sampleCode = this.$route.query.sampleCode || '';
    // 如果需要在页面加载时自动查询，可以调用 fetchReports 方法
    if (this.formData.sampleCode) {
      this.fetchReports();
    }
  },mounted() {
    // 从 localStorage 加载数据
    const savedData = localStorage.getItem('formData');
    if (savedData) {
      const data = JSON.parse(savedData);
      this.formData.name = data.name;
      this.formData.phone = data.phone;
      this.formData.cpid = data.phone;
    }
  },
  methods: {
    async fetchReports() {
      try {
        const response = await axios.post( `${process.env.VUE_APP_API_URL}/api/omicsfit/reportList?name=`+this.formData.name+'&phone='+this.formData.phone+'&cpid='+this.formData.phone+'&sampleCode='+this.formData.sampleCode, {});
        console.log('获取报告列表成功:', response.data);
        if (Array.isArray(response.data)&&response.data.length > 0) {
            this.reports = response.data;
        }else{
            this.reports=[];
            const response = await axios.post(`${process.env.VUE_APP_API_URL}/api/omicsfit/getSample?name=`+this.formData.name+'&phone='+this.formData.phone+'&cpid='+this.formData.phone+'&sampleCode='+this.formData.sampleCode+'', {});
            console.log('获取报告列表成功:', response.data);
            if (Array.isArray(response.data)&&response.data.length > 0) {
                this.reportsmsg=response.data;
                    
                }

            }
        
        
      } catch (error) {
        console.error('获取报告列表失败:', error);
      }
    }
  }
};
</script>

<style scoped>

a {
    color: #42b983;
  }

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 8px;
  text-align: left;
}


th {
  background-color: #f2f2f2;
}
button {
    font-size: larger;
  }
  .main {
  padding: 20px;
}
</style>