<template>
  <div class="main">
    <form @submit.prevent="openReport(this.formData.sampleCode)" method="post">
      <table border="0" width="100%">
        <tbody>
          <tr>
            <td><span for="name">姓名:</span></td>
            <td width="70%"><input type="text" id="name" v-model="formData.name"  placeholder="请填写"   required /></td>
          </tr>
          <tr>
            <td><span for="phone">电话:</span></td>
            <td><input type="text" id="phone" v-model="formData.phone"  placeholder="请填写"  required /></td>
          </tr>
          <tr>
            <td><span for="sampleCode">样本条码:</span></td>
            <td><input type="text" id="sampleCode" v-model="formData.sampleCode"  placeholder="请填写"   required /></td>
          </tr>
        </tbody>
      </table>
      <button type="submit">查询</button>
    </form>

    <ul v-if="Samplearray&&Samplearray.length > 0">
      <li v-for="(samp, index) in Samplearray" :key="index">
        <label>报告：</label>{{ samp.sampleCode }}
        <button class="report-button" @click="openReport(samp.sampleCode)">查询报告</button>
      </li>
    </ul>
    <ul v-else>
      <li>请提取样本后，使用添加检测功能绑定检测盒。</li>
    </ul>

    

    <!-- 弹出层 -->
    <div id="reportbox" v-if="isReportBoxVisible" @click="closeReportBox">
      <div class="report-content" @click.stop>
        <div class="report-header"> <img  src="../assets/ico4591@3x.png" width="40px"><label>检测报告</label></div>
        <button class="close-button" @click="closeReportBox">
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 45.6 45.6001">
	<path transform="matrix(1 0 0 1 0 0)" d="M22.8 0C10.2079 0 0 10.2079 0 22.8L0 22.8342C0 35.4263 10.1737 45.6 22.7658 45.6L22.8 45.6C35.3921 45.6 45.6 35.4263 45.6 22.8342L45.6 22.8C45.6 10.2079 35.4263 0 22.8342 0L22.8 0Z" fill-rule="nonzero" fill="rgb(255, 255, 255)" fill-opacity="0.7"/>
	<path transform="matrix(1 0 0 1 0 0)" d="M-0 22.8C0 10.2079 10.2079 -0 22.8 -0C35.3921 0 45.6 10.2079 45.6 22.8C45.6 35.3921 35.3921 45.6 22.8 45.6C10.2079 45.6 -0 35.3921 -0 22.8L0 22.8ZM22.8 1.2C10.8706 1.2 1.2 10.8706 1.2 22.8C1.2 34.7294 10.8706 44.4 22.8 44.4C34.7294 44.4 44.4 34.7294 44.4 22.8C44.4 10.8706 34.7294 1.2 22.8 1.2L22.8 1.2Z" fill-rule="nonzero" fill="rgb(255, 255, 255)"/>
	<g opacity="0.6">
		<path transform="matrix(0.707107 0.707107 -0.707107 0.707107 14.9984 11.7811)" d="M0 2C-1.19209e-07 3.10456 0.89544 4 2 4L12.3082 4C13.4128 4 14.3082 3.10456 14.3082 2C14.3082 0.895441 13.4128 9.53674e-07 12.3082 8.34465e-07L2 0C0.89544 -1.19209e-07 0 0.89544 0 2ZM17.1669 2.00738C17.1669 3.11194 18.0623 4.00738 19.1669 4.00738L24.7879 4.00738C25.8924 4.00738 26.7879 3.11194 26.7879 2.00738C26.7879 0.902821 25.8924 0.0073806 24.7879 0.00738049L19.1669 0.00738001C18.0623 0.00737989 17.1669 0.90282 17.1669 2.00738Z" fill-rule="evenodd" fill="rgb(36, 53, 65)"/>
		<path transform="matrix(0.707107 0.707107 -0.707107 0.707107 30.8817 14.376)" d="M0.316667 -2C-0.787893 -2 -1.68333 -1.10456 -1.68333 0L-1.68333 22.8C-1.68333 23.9046 -0.787893 24.8 0.316667 24.8C1.42123 24.8 2.31667 23.9046 2.31667 22.8L2.31667 0C2.31667 -1.10456 1.42123 -2 0.316667 -2Z" fill-rule="evenodd" fill="rgb(36, 53, 65)"/>
	</g>
</svg>
</button>

<div class="reportsdivhi"  v-if="reports&&reports.length > 0">
  <div class="reportsdiv"  v-for="(report, index) in reports" :key="index">
      <table  border="0" width="100%">
        <tbody>
          <tr>
            <td>
              <b>{{ report.name }}</b>
              <br />
              <span>出生日期：</span>{{ report.birth }}<br />
              <span>报告编号：</span>{{ report.sampleReportCode }}
            </td>
            <td>
              <br />
            <button  v-if="report.adopt!=1" class="unable-button">检测中...</button>
             <a :href="report.reportUrl" v-if="report.adopt==1" class="report-button" target="_blank">查看报告</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    </div>


        <table v-else-if="reportsmsg&&reportsmsg.length > 0" border="0"  width="100%">
          <tbody>
            <tr><td>正在检测中:</td></tr>
            <tr v-for="(report, index) in filteredReportsmsg" :key="index">
              <td>{{ index+1 }}. {{ report.stateStr }}</td>
              <td>状态：{{ report.stateFlag ? '完成' : '进行中' }}</td>
            </tr>
          </tbody>
        </table>
        <p v-else>{{ this.reporterrmsg }}</p>
        
   
    </div>
  
      </div>

  </div>
</template>
<script>
import axios from 'axios';
 
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {
      Samplearray: [],
      reports: [],
      reportsmsg: [],
      reporterrmsg:'',
      formData: {
        name: '',
        phone: '',
        cpid: '',
        sampleCode: ''
      },
      isReportBoxVisible: false,
      selectedReport: null
    };
  },
  computed: {
    filteredReportsmsg() {
      return this.reportsmsg.filter(report => report.stateStr && report.stateFlag);
    }
  },
  created() {
    // 从 URL 参数中获取 sampleCode 并赋值给 formData.sampleCode
    const savedData = localStorage.getItem('formData');
    if (savedData) {
      const data = JSON.parse(savedData);
      this.formData.name = data.name;
      this.formData.phone = data.phone;
      this.formData.cpid = data.phone;
    }
    this.formData.sampleCode = this.$route.query.sampleCode || '';
    // 如果需要在页面加载时自动查询，可以调用 fetchReports 方法
     
    if (this.formData.sampleCode) {
      this.openReport(this.formData.sampleCode);
    }
  },
  mounted() {
  // const script = document.createElement('script');
  // script.src = 'http://res.wx.qq.com/open/js/jweixin-1.6.0.js';
  // script.onload = () => {
  //   //TODO 对接中
  //  // this.configWechatSDK();
  // };
  // document.head.appendChild(script);
    // 从 localStorage 加载数据
    var retrievedArray = localStorage.getItem('arrayKey');
    retrievedArray = JSON.parse(retrievedArray);
    if (retrievedArray) {
      this.Samplearray = retrievedArray;
    }
    },
  methods: {
    async fetchReports() {

       
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}/api/omicsfit/reportList?name=${this.formData.name}&phone=${this.formData.phone}&cpid=${this.formData.phone}&sampleCode=${this.formData.sampleCode}`,
          {}
        );
        console.log('获取报告成功:', response.data);
        if (Array.isArray(response.data) && response.data.length > 0) {
          this.reports = response.data;
          this.isReportBoxVisible = true;

          //查询到正确的报告，存到缓存localStorage中。 
          var rdata = {
            name: response.data.name,
            phone: response.data.phone,
            cpid: response.data.phone, // 根据 curl 请求，cpid 和 phone 是相同的
            sampleCode:this.formData.sampleCode ,
            sex: response.data.sex,
            birth: response.data.birth,
            birthAddress: response.data.birthAddress
          }
            var retrievedArray = localStorage.getItem('arrayKey');
            retrievedArray = JSON.parse(retrievedArray);
            if(retrievedArray){
              for (let index = 0; index < retrievedArray.length; index++) {
                if(rdata.sampleCode==retrievedArray[index].sampleCode){
                  return;
              }
                
              }
              retrievedArray.push(rdata);
            }else{
                retrievedArray = [];
                retrievedArray.push(rdata);
              }
              
              localStorage.setItem('arrayKey', JSON.stringify(retrievedArray));
              if (retrievedArray) {
                this.Samplearray = retrievedArray;
              }
        } else {
          this.reports = [];
          const response = await axios.post(
            `${process.env.VUE_APP_API_URL}/api/omicsfit/getSample?name=${this.formData.name}&phone=${this.formData.phone}&cpid=${this.formData.phone}&sampleCode=${this.formData.sampleCode}`,
            {}
          );
          console.log('获取报告列表成功:', response.data);
          if (Array.isArray(response.data) && response.data.length > 0) {
            this.reportsmsg = response.data;
            this.isReportBoxVisible = true;
          }else{
            this.reporterrmsg=response.data;
          }
        }
      } catch (error) {
        console.error('获取报告列表失败:', error);
      }
    },
    openReport(sampleCode) {
      this.formData.sampleCode =sampleCode;
      this.fetchReports();


        this.isReportBoxVisible = true;

    },
    closeReportBox() {
      this.isReportBoxVisible = false;
      this.selectedReport = null;
      this.reportsmsg=null;
      this.reports=null;
    }, async configWechatSDK() {
    // try {
    //   // 假设通过后端API获取配置参数
    //   const response = await fetch('/api/wechat/config');
    //   const { appId, timestamp, nonceStr, signature } = await response.json();

    //   wx.config({
    //     debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
    //     appId:'wx3c92cd80d3f1161f', // 必填，公众号的唯一标识
    //     timestamp:'', // 必填，生成签名的时间戳
    //     nonceStr:'', // 必填，生成签名的随机串
    //     signature:'', // 必填，签名
    //     jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'] // 必填，需要使用的JS接口列表
    //   });

    //   wx.ready(() => {
    //     // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
    //     console.log('wx.config ready');
    //   });

    //   wx.error((res) => {
    //     // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
    //     console.error('wx.config error', res);
    //   });
    // } catch (error) {
    //   console.error('Failed to fetch wechat config', error);
    // }
  }
  }
};
</script>

<style scoped>
table {
  padding: 5%;
}
td {
  text-align: left;
  padding: 7px 0px 7px 0px;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0px;
  background: #F5F7FA;
  border-radius: 12px;
  width: 80%;
  margin: 10px;
  padding: 20px 20px 20px 20px;
  line-height: 30px;
  text-align: left;
  color: #1B2126;
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 600;
  font-size:16px;
}
 
label {
  color: #8D9092;
  font-weight: 200;
  font-size:15px;
}

a {
  color: #42b983;
}

.report-button {
  width: auto;
  background: #33DB81;
  border-radius: 8px;
  color: #FFFFFF;
  font-size: 14px;
  text-align: center;
  border: 0px;
  text-decoration: none;
  float: right;
  line-height: 20px;
  letter-spacing: 1.76px;
  padding: 5px 10px 5px 10px ;
}
.unable-button {
  width: auto;
  background: #F5F7FA;
  border-radius: 8px;
  color: #8D9092;
  font-size: 12px;
  text-align: center;
  padding: 5px;
  border: 0px;
  text-decoration: none;
  float: right;
  line-height: 20px;
  letter-spacing: 1.76px;
}

#reportbox {
  position: fixed; /* 固定位置 */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 半透明背景 */
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.report-content {
  position: absolute; /* 固定位置 */
  bottom: 0px;
  left: 0px;
  width: 90%;
  flex-shrink: 0;
  background: #F5F7FA;
  border-radius: 32px 32px 0px 0px;
  padding: 20px;
  z-index: 999;
}

.close-button {
  position:absolute;
  top: 10px;
  right: 30px;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: #333;
  width: 24px;
height: 24px;
flex-shrink: 0;


}
.report-header {

  text-align: left;
}
.report-header img{
float: left;
}
.report-header label{
  line-height: 40px; /* 1 */
  color: #1B2126;
font-family: "Alibaba PuHuiTi 3.0";
font-size: 20px;
font-style: normal;
font-weight: 900;
letter-spacing: 1px;
padding-left: 10px;
line-height: 40px;
}
.report-content table{
background: #FFFFFF;
border-radius: 24px;
padding: 5px 20px 5px 20px;

}

.reportsdivhi {
    max-height: 400px;
  }

/* 响应式设计 */
@media (max-height: 932px) {
  .reportsdivhi {
    max-height: 600px;
  }
}
@media (max-height: 640px) {
  .reportsdivhi {
    max-height: 300px;
  }
}

.reportsdiv{
padding: 10px 0px 10px 0px;
line-height: 30px;

}

.reportsdiv span {
  color: #8D9092;
}

</style>