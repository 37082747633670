<template>
  <div class="topmenu">
    <div class="tab-switch">
      <div class="tab-buttons">
        <router-link 
          v-for="(tab, index) in tabs" 
          :key="index" 
          :to="tab.path"
          :class="{ active: activeTab === index }"
          custom
          v-slot="{ navigate, href }"
        >
          <button 
            :href="href"
            @click="handleClick(navigate, index)"
          >
            {{ tab.title }}
            <img id="activeimg" v-if="index==this.activeTab" alt="active img" src="../assets/2968@3x.png" width="10px">

          </button>

        </router-link>
      </div>
    </div>
    <img id="topmenubg" alt="top bg menu" :src="backgroundImage" width="100%">
  </div>
</template>

<script>
export default {
  name: 'theTopMenu',
  data() {
    return {
      activeTab: 0,
      tabs: [
        { title: '添加检测', content: '绑定采集盒条码', path: '/new' },
        { title: '报告列表', content: '查询检测报告', path: '/' }
      ],
      backgroundImage: require('../assets/tab1@3x.png') // 默认背景图片
    };
  },
  watch: {
    $route(to) {
      this.updateBackgroundImage(to.path);
    }
  },
  methods: {
    handleClick(navigate, index) {
      this.activateTab(index);
      navigate();
    },
    activateTab(index) {
      this.activeTab = index;
 
    },
    updateBackgroundImage(path) {
      if (path === '/new') {
        this.backgroundImage = require('../assets/tab1@3x.png');
        this.activeTab=0;
      } else {
        this.backgroundImage = require('../assets/tab2@3x.png');
        this.activeTab=1;
      }
      console.log(this.activeTab);
    }
  },
  created() {
    this.updateBackgroundImage(this.$route.path);
  }
};
</script>

<style scoped>
.tab-switch {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.tab-buttons {
  display: flex;
  justify-content: space-around;
  border-bottom: 20px solid #ffffff;

}

.tab-buttons button {
  padding: 10px 20px;
  border: none;
  background: none;
  cursor: pointer;
  outline: none;
  font-size: 16px;
  letter-spacing: 1px;
  color: #1B2126;
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 1 */
  text-align: center;

}

.tab-buttons button.active {
  border-bottom: 2px solid #42b983;
  color: #42b983;
}

.tab-pane {
  padding: 10px;
}

#topmenubg {
  position: relative; /* 固定位置 */
  top: -60px; /* 顶部对齐 */
  left: 0; /* 左侧对齐 */
  width: 100%; /* 宽度占满整个屏幕 */
  height: auto; /* 高度自适应 */
  z-index: -1; /* 确保图片在其他内容之下 */

}
#activeimg {
  position:relative;
  top: 8px;
}
</style>